import Button from '@atoms/Button/Button';
import NavigationItem from '@atoms/NavigationItem/NavigationItem';
import { A11Y_PANEL_ID } from '@constants/constants';
import useStore from '@hooks/useStore';
import useTranslations from '@hooks/useTranslations';
import LanguageSwitch from '@molecules/LanguageSwitch/LanguageSwitch';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

import styles from './MainMenu.module.scss';
import SubMenu from './SubMenu';

interface Props {
  closePanel: () => void;
}

export default function MainMenu({ closePanel }: Props) {
  const { asPath } = useRouter();

  const [shownSubmenuId, setShownSubmenuId] = useState<string | undefined>(
    undefined
  );

  const {
    store: { config: { mainMenu, secondaryMenu } = {}, showAccessibilityPanel },
    setStore,
  } = useStore();

  const { a11y } = useTranslations();

  const topLevelLinks = useMemo(
    () =>
      secondaryMenu?.filter(
        ({ internalLink, externalLink }) =>
          (internalLink && internalLink[0]) || externalLink
      ),
    [secondaryMenu]
  );

  const toggleSubMenu = (id?: string): void => {
    setShownSubmenuId(id);
  };

  /* Close menus when changing route */
  useEffect(() => {
    if (shownSubmenuId) {
      toggleSubMenu();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath]);

  return (
    <nav className={styles.nav}>
      {/* Main menu */}
      {!!mainMenu?.length && (
        <ul className={clsx(styles.list, styles.border, 'u-list-clean')}>
          {mainMenu.map(menuItem => (
            <li key={menuItem.id} className={styles.listItem}>
              {menuItem.children.length ? (
                <SubMenu
                  menuItem={menuItem}
                  shownSubmenuId={shownSubmenuId}
                  toggleSubMenu={toggleSubMenu}
                />
              ) : (
                <NavigationItem
                  item={menuItem}
                  closePanel={closePanel}
                  className="u-menu-link"
                />
              )}
            </li>
          ))}
        </ul>
      )}

      {/* Secondary Menu */}
      {!!topLevelLinks?.length && (
        <ul className={clsx(styles.list, styles.border, 'u-list-clean')}>
          {topLevelLinks.map(menuItem => (
            <li key={menuItem.id}>
              <NavigationItem
                item={menuItem}
                closePanel={closePanel}
                className={styles.link}
              />
            </li>
          ))}
        </ul>
      )}

      {/* Bottom content */}
      <div className={styles.switch}>
        <LanguageSwitch id="main-menu-lang" />
        <Button
          className={styles.accessibility}
          iconLeft="ACCESSIBILITY"
          variant="white"
          label={a11y}
          aria-controls={A11Y_PANEL_ID}
          aria-expanded={showAccessibilityPanel ? 'true' : 'false'}
          onClick={() => {
            setStore(s => ({
              ...s,
              showAccessibilityPanel: !s.showAccessibilityPanel,
            }));
          }}
        />
      </div>
    </nav>
  );
}
