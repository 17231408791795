import ChevronDownIcon from '@svg/chevron-down.svg';
import { IconType } from '@type-declarations/svg';
import clsx from 'clsx';
import { ChangeEvent, HTMLAttributes } from 'react';

import styles from './SelectInput.module.scss';

interface Props extends HTMLAttributes<HTMLSelectElement> {
  id: string;
  name?: string;
  placeholder?: string;
  label: string;
  value?: string;
  Icon?: IconType;
  withShadow?: boolean;
  fitContent?: boolean;
  colorVariant?: 'gray' | 'lightGray';
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  options: {
    value: string;
    label: string;
  }[];
}

export default function SelectInput({
  id,
  name,
  Icon,
  label,
  value,
  options,
  onChange,
  fitContent,
  withShadow,
  placeholder,
  colorVariant,
  ...attributes
}: Props) {
  return (
    <div
      className={clsx(
        styles.wrapper,
        withShadow && styles.shadow,
        fitContent && styles.fitContent,
        colorVariant && styles[colorVariant]
      )}
    >
      {Icon && <Icon className={styles.icon} />}

      <select
        aria-label={label}
        className={clsx(styles.select, Icon && styles.withIcon)}
        onChange={onChange}
        defaultValue={value}
        id={id}
        name={name}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...attributes}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options.map(option => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      <ChevronDownIcon className={styles.chevron} />
    </div>
  );
}
