'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import NProgress from 'nprogress';
import { MouseEvent, useEffect } from 'react';

interface Props {
  stopDelayMs?: number;
}

export const routeChangeStart = (e?: MouseEvent<HTMLAnchorElement>) => {
  if (e?.currentTarget.getAttribute('target') === '_blank') return;

  if (NProgress.isStarted()) return;

  NProgress.set(0.3);
  NProgress.start();
};

export default function NextNProgress({ stopDelayMs = 150 }: Props) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  let timer: NodeJS.Timeout | undefined;

  const routeChangeEnd = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      NProgress.done(true);
    }, stopDelayMs);
  };

  useEffect(() => {
    routeChangeStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    routeChangeEnd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return null;
}
