import Button from '@atoms/Button/Button';
import EmptyMessage from '@atoms/EmptyMessage/EmptyMessage';
import { ROUTES } from '@constants/constants';
import useBookmarks from '@hooks/useBookmarks';
import useStore from '@hooks/useStore';
import useStoreBookmarks from '@hooks/useStoreBookmarks';
import useTranslations from '@hooks/useTranslations';
import Card from '@molecules/Card/Card';
import Loader from '@molecules/Loader/Loader';
import getInternalRoute from '@utils/getInternalRoute';
import handleShare from '@utils/handleShare';
import clsx from 'clsx';

import styles from './BookmarkList.module.scss';

export default function BookmarkList() {
  const {
    store: { locale },
  } = useStore();

  const { bookmarks } = useStoreBookmarks();

  const { emptyListTitle, emptyListText, urlCopied, shareMyList } =
    useTranslations();

  const { items, total, loading } = useBookmarks();

  const appendedUrl = bookmarks.length
    ? `${locale}/${ROUTES.SharedListPage[locale]}?id=${bookmarks.join('&id=')}`
    : '';

  if (loading) {
    return <Loader loading={loading} />;
  }

  if (!total) {
    return (
      <EmptyMessage
        title={emptyListTitle}
        text={emptyListText}
        url="/explore"
      />
    );
  }

  return (
    <>
      <Button
        label={shareMyList}
        onClick={() => handleShare({ urlCopied, appendedUrl })}
        iconLeft="SHARE_IOS"
        icon="NAV_ARROW_RIGHT"
        className={styles.shareButton}
      />
      <ul className={clsx(styles.list, 'u-list-clean')}>
        {items.map(bookmark => (
          <li key={bookmark.id}>
            <Card
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...bookmark}
              key={bookmark.id}
              id={bookmark.id}
              showSaveButton
              imageSize="sm"
              imageSizeBreakpoints={['md@from-md', 'lg@from-lg']}
              href={getInternalRoute({
                internalLink: bookmark,
                locale,
              })}
            />
          </li>
        ))}
      </ul>
    </>
  );
}
