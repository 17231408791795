import ColorPalette from '@atoms/ColorPalette/ColorPalette';
import Seo from '@atoms/Seo/Seo';
import AccessibilityPanel from '@molecules/Accessiblity/AccessibilityPanel';
import { GoogleTagManager } from '@next/third-parties/google';
import Footer from '@organisms/Footer/Footer';
import Header from '@organisms/Header/Header';
import { HeroVariant, PaletteVariants } from '@type-declarations/hero';
import { Image } from '@type-declarations/media';
import { Page } from '@type-declarations/page';
import { SeoProps } from '@type-declarations/seo';
import { getColorPalette } from '@utils/getColorPalette';
import { PropsWithChildren, useMemo } from 'react';

interface Props extends PropsWithChildren {
  title?: string;
  suffix?: string;
  heroVariant: HeroVariant | null;
  seo?: SeoProps;
  image?: Image;
  introText?: string;
  page?: Page;
}

function BaseTemplate({
  children,
  title,
  suffix = 'Explore Breda',
  seo,
  heroVariant = 'small',
  image,
  introText,
  page,
}: Props) {
  const colorPalette: PaletteVariants = useMemo(
    () => getColorPalette({ page }),
    [page]
  );
  const isCampaignPage = page?.typename === 'ZigzagCampaignPage';

  return (
    <>
      <GoogleTagManager gtmId={process.env.GTM_CODE as string} />

      <ColorPalette colorPalette={colorPalette} />

      <Seo
        title={title}
        suffix={suffix}
        seo={seo}
        image={image}
        description={introText}
      />

      <Header heroVariant={heroVariant} isCampaignPage={isCampaignPage} />
      <AccessibilityPanel />

      <main>{children}</main>
      <Footer isCampaignPage={isCampaignPage} />
    </>
  );
}

export default BaseTemplate;
