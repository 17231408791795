import clsx from 'clsx';

import styles from './SimpleLoader.module.scss';

interface Props {
  size?: number;
  className?: string;
}

export default function SimpleLoader({ size = 40, className }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      className={clsx(styles.spinner, className)}
    >
      <circle
        cx="10"
        cy="10"
        r="8"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        pathLength="10"
        strokeDasharray="8 2"
        strokeLinecap="round"
      />
    </svg>
  );
}
