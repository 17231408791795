import WrapInLink from '@atoms/Button/WrapInLink';
import IconLogo from '@svg/logo.svg';
import clsx from 'clsx';

import styles from './LogoLink.module.scss';

interface LogoLinkProps {
  zigzagCampaignHero?: boolean;
}

export default function LogoLink({ zigzagCampaignHero }: LogoLinkProps) {
  return (
    <WrapInLink
      href="/"
      className={clsx(
        styles.logoLink,
        zigzagCampaignHero && styles.zigzagCampaignHero
      )}
      aria-label="Home"
    >
      <IconLogo className={styles.logo} />
    </WrapInLink>
  );
}
