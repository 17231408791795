import NavigationItem from '@atoms/NavigationItem/NavigationItem';
import useStore from '@hooks/useStore';
import { HeroVariant } from '@type-declarations/hero';
import clsx from 'clsx';
import { useInView } from 'react-intersection-observer';

import styles from './Header.module.scss';
import LogoLink from './LogoLink';
import SidePanel from './SidePanel';

interface Props {
  isCampaignPage?: boolean;
  heroVariant: HeroVariant | null;
}

export default function Header({ heroVariant, isCampaignPage }: Props) {
  const { ref, inView } = useInView({
    delay: 0,
  });

  const {
    store: { config: { mainMenu } = {}, page },
  } = useStore();

  const topLevelLinks = mainMenu?.filter(
    ({ internalLink, externalLink }) =>
      (internalLink && internalLink[0]) || externalLink
  );

  return (
    <header
      className={clsx(
        styles.header,
        heroVariant && styles.hasHero,
        heroVariant === 'large' && styles.hasLargeHero,
        (!heroVariant || !page?.image) && styles.solid,
        heroVariant && !page?.image && styles.hasHeroWithoutImage,
        isCampaignPage && styles.isCampaignPage
      )}
    >
      {/* Static TOP-BAR with logo en additional menu-items */}
      {!isCampaignPage && (
        <div className={styles.topBar}>
          <div ref={ref} className={styles.inViewRef} />
          <div className={clsx(styles.container, 'u-container-width')}>
            <LogoLink />

            {/* Some menu-items here (<ul>) */}
            {!!topLevelLinks?.length && (
              <ul className={clsx(styles.list, 'u-list-clean')}>
                {topLevelLinks.map(menuItem => (
                  <li key={menuItem.id}>
                    <NavigationItem item={menuItem} className={styles.link} />
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      )}

      {/* Fixed sidepanel (top-right) */}
      <SidePanel
        inView={inView}
        canExpand={!!heroVariant && !!page?.image}
        isCampaignPage={isCampaignPage}
      />
    </header>
  );
}
