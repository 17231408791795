import { routeChangeStart } from '@atoms/NProgress/NProgress';
import { PANEL_IDS } from '@constants/constants';
import useTranslations from '@hooks/useTranslations';
import BookmarkList from '@molecules/MenuStates/BookmarkList';
import IconLogo from '@svg/logo.svg';
import { MenuState } from '@type-declarations/menu';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { CSSTransition } from 'react-transition-group';

import MainMenu from './MainMenu';
import styles from './MainPanel.module.scss';
import MainPanelItem from './MainPanelItem';

interface Props {
  menuState: MenuState;
  closePanel: () => void;
}

export default function MainPanel({ menuState, closePanel }: Props) {
  const router = useRouter();

  const t = useTranslations();

  const handleClick = () => {
    routeChangeStart();

    if (router.asPath === '/') {
      closePanel();
    }
  };

  // Dynamically import SearchPanel to improve performance
  const SearchPanel = dynamic(() => import('./SearchPanel'));

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        onClick={closePanel}
        onKeyDown={closePanel}
        className={clsx(styles.backdrop, !!menuState && styles.showBackdrop)}
      />

      <CSSTransition
        in={!!menuState}
        timeout={300}
        unmountOnExit
        classNames={{
          enter: styles.fadeEnter,
          enterActive: styles.fadeEnterActive,
          exit: styles.fadeExit,
          exitActive: styles.fadeExitActive,
        }}
      >
        <div className={styles.panel}>
          <div className={styles.panelContainer}>
            <div className="u-container-width">
              <Link
                href="/"
                className={styles.logoLink}
                aria-label="Home"
                onClick={handleClick}
              >
                <IconLogo className={styles.logo} />
              </Link>
            </div>

            {menuState === 'main' && (
              <MainPanelItem
                id={PANEL_IDS.main}
                title="Menu"
                closePanel={closePanel}
              >
                <MainMenu closePanel={closePanel} />
              </MainPanelItem>
            )}

            {menuState === 'list' && (
              <MainPanelItem
                id={PANEL_IDS.list}
                title={t.myList}
                closePanel={closePanel}
              >
                <BookmarkList />
              </MainPanelItem>
            )}

            {menuState === 'search' && (
              <SearchPanel id={PANEL_IDS.search} closePanel={closePanel} />
            )}
          </div>
        </div>
      </CSSTransition>
    </>
  );
}
