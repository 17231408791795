import clsx from 'clsx';
import { PropsWithChildren } from 'react';

import styles from './Loader.module.scss';
import SimpleLoader from './SimpleLoader';

interface Props extends PropsWithChildren {
  loading: boolean;
  className?: string;
  modifier?: 'verticalPadding';
}

export default function Loader({
  loading,
  className,
  children,
  modifier,
}: Props) {
  return (
    <div className={clsx(styles.container, modifier && styles[modifier])}>
      {children}
      {loading && (
        <div className={styles.overlay}>
          <div
            className={clsx(
              styles,
              'loader',
              className,
              !children && styles.absolute
            )}
          >
            <SimpleLoader className={styles.spinner} />
          </div>
        </div>
      )}
    </div>
  );
}
