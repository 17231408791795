import { routeChangeStart } from '@atoms/NProgress/NProgress';
import { Locale } from '@type-declarations/locale';
import { MenuItem } from '@type-declarations/menu';
import getInternalRoute from '@utils/getInternalRoute';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';

interface Props {
  item: MenuItem;
  className?: string;
  closePanel?: () => void;
}

function NavigationItem({ item, className, closePanel }: Props) {
  const { asPath } = useRouter();
  const locale = useRouter().locale as Locale;
  const { internalLink, externalLink, title } = item;

  if (internalLink && internalLink[0]) {
    const route = getInternalRoute({
      internalLink: internalLink[0],
      locale,
    });

    if (!route) return null;

    const handleClick = () => {
      routeChangeStart();

      if (closePanel && asPath === `/${route}`) {
        closePanel();
      }
    };

    return (
      <Link
        href={route}
        className={clsx(
          className,
          asPath === `/${route}` && 'u-menu-link--active'
        )}
        itemProp="url"
        onClick={handleClick}
      >
        {title}
      </Link>
    );
  }

  if (externalLink) {
    return (
      <a
        href={externalLink}
        className={className}
        target="_blank"
        rel="noopener"
        itemProp="url"
      >
        {title}
      </a>
    );
  }

  return null;
}

export default NavigationItem;
