const getInternalUrl = url => {
  if (!url || typeof url !== 'string') return null;

  const ABSOLUTE_PATH_REGEXP = /(http|https|http:|https:|www\.|\.nl|\.com)/gi;
  // Url is a relative path, thus internal.
  if (!url.match(ABSOLUTE_PATH_REGEXP)) return url;

  const INTERNAL_DOMAINS_REGEXP = process.env.ENVIRONMENT_URL;
  // Url is absolute but contains internal domains, thus internal
  if (url.match(INTERNAL_DOMAINS_REGEXP)) {
    return `/${url.split(process.env.ENVIRONMENT_URL)[1]}`;
  }

  // All checks failed, url is external
  return null;
};

export default getInternalUrl;
