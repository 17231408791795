import ErrorMessage from '@atoms/ErrorMessage/ErrorMessage';
import Icon, { IconType } from '@atoms/Icon/Icon';
import { Error } from '@type-declarations/error';
import clsx from 'clsx';
import {
  ChangeEvent,
  InputHTMLAttributes,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';

import styles from './TextInput.module.scss';

interface Props
  extends PropsWithChildren<
    InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>
  > {
  type?: 'search' | 'email' | 'text' | 'number' | 'textarea';
  label: string;
  hideLabel?: boolean;
  onChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  icon?: IconType | null;
  iconClassName?: string;
  wrapperClassName?: string | null;
  error?: Error;
}

function TextInput({
  type = 'text',
  name,
  label,
  required = false,
  onChange,
  className,
  icon,
  iconClassName,
  wrapperClassName,
  children,
  id,
  hideLabel,
  value,
  defaultValue,
  error,
  ...attributes
}: Props) {
  const [inputValue, setInputValue] = useState(value || defaultValue || '');

  useEffect(() => {
    setInputValue(value || defaultValue || '');
  }, [value, defaultValue]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInputValue(e.currentTarget.value);

    if (onChange) {
      onChange(e);
    }
  };

  const InputEl = type === 'textarea' ? 'textarea' : 'input';

  return (
    <div
      className={clsx(
        styles.wrapper,
        wrapperClassName,
        !!inputValue && styles.hasValue
      )}
    >
      {icon && (
        <Icon icon={icon} className={clsx(styles.icon, iconClassName)} />
      )}

      {id && !hideLabel && (
        <label className={styles.label} htmlFor={id}>
          {label}
          {required && <span className={styles.asterix}> *</span>}
        </label>
      )}

      <InputEl
        id={id}
        type={type}
        name={name}
        className={clsx(styles.input, className)}
        onChange={handleChange}
        required={required}
        aria-label={label}
        value={inputValue}
        rows={4}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...attributes}
      />

      <ErrorMessage message={error?.message} />

      {children}
    </div>
  );
}

export default TextInput;
