import Icon from '@atoms/Icon/Icon';
import NavigationItem from '@atoms/NavigationItem/NavigationItem';
import useFocusTrap from '@hooks/useFocusTrap';
import { MenuItem } from '@type-declarations/menu';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

import styles from './SubMenu.module.scss';

interface Props {
  menuItem: MenuItem;
  parentId?: string;
  shownSubmenuId?: string;
  toggleSubMenu: (id?: string) => void;
}

export default function SubMenu({
  menuItem,
  parentId,
  shownSubmenuId,
  toggleSubMenu,
}: Props) {
  const [screenSize, setScreenSize] = useState(0);
  const ref = useRef<HTMLUListElement>(null);
  const toggleRef = useRef<HTMLButtonElement>(null);
  const menuItemRef = useRef<HTMLButtonElement>(null);
  const { id } = menuItem;
  const subMenuIsShown = shownSubmenuId?.includes(id);
  const subMenuChildIsShown = shownSubmenuId?.includes('&');
  const SUB_MENU_TOGGLE_ID = `submenu-toggle-${id}`;
  const SUB_MENU_ID = `submenu-${id}`;
  const SUB_MENU_TITLE_ID = `submenu-toggle-${id}`;

  const handleToggleSubmenu = () => {
    if (subMenuIsShown) {
      toggleSubMenu(parentId);
      menuItemRef.current?.focus();
      return;
    }

    if (!subMenuIsShown && !parentId) {
      toggleSubMenu(id);
      return;
    }
    if (!subMenuIsShown && parentId) {
      // When the submenu is a child of a submenu we want to keep the parent id,
      // this is done so the check on line 11 still returns true and a11y doens't break.
      toggleSubMenu(`${parentId}&${id}`);
    }
  };

  const { enableFocusTrap, disableFocusTrap } = useFocusTrap(ref, toggleRef);

  useEffect(() => {
    if (screenSize < 1024) {
      enableFocusTrap(`:scope > li > a, :scope > li > button`);
    } else {
      disableFocusTrap();
    }

    return () => {
      disableFocusTrap();
    };
  }, [screenSize, enableFocusTrap, disableFocusTrap]);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    setScreenSize(window.innerWidth);
    // Add event listener only if the window width is greater than or equal to 720px
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (subMenuIsShown) {
      toggleRef.current?.focus();
    }
  }, [subMenuIsShown]);

  return (
    <>
      {/* Submenu Toggle button */}
      <button
        ref={menuItemRef}
        type="button"
        id={SUB_MENU_TOGGLE_ID}
        className={clsx(
          styles.subMenuLink,
          'u-menu-link',
          subMenuChildIsShown && styles.subMenuChildIsShown,
          parentId && styles.nestedSubMenuLink
        )}
        onClick={handleToggleSubmenu}
        aria-expanded={subMenuIsShown}
        aria-haspopup
        aria-controls={SUB_MENU_ID}
      >
        {menuItem.title}
        <Icon icon="NAV_ARROW_RIGHT" className={styles.toggleIcon} />
      </button>

      {/* Submenu list */}
      <ul
        ref={ref}
        id={SUB_MENU_ID}
        aria-labelledby={SUB_MENU_TITLE_ID}
        className={clsx(
          styles.list,
          'u-list-clean',
          'u-container-width',
          parentId && styles.nestedList,
          subMenuIsShown && styles.subMenuIsShown
        )}
      >
        {/* Submenu title */}
        <li className={styles.listItem}>
          <h3 id={SUB_MENU_TITLE_ID} className={styles.title}>
            {menuItem.title}
          </h3>
        </li>

        {/* Back button */}
        <li className={clsx(styles.listItem, styles.backItem)}>
          <button
            ref={toggleRef}
            type="button"
            className={clsx(styles.backButton, 'u-menu-link')}
            onClick={handleToggleSubmenu}
            aria-label="Sluit sub-menu"
            aria-expanded={subMenuIsShown}
            aria-haspopup
            aria-controls={SUB_MENU_ID}
          >
            <Icon icon="NAV_ARROW_LEFT" className={styles.backIcon} />
            {menuItem.title}
          </button>
        </li>

        {/* List all items */}
        {menuItem.children.map(subMenuItem => (
          <li
            className={clsx(styles.listItem, styles.subMenuItem)}
            key={subMenuItem.id}
          >
            <NavigationItem
              item={subMenuItem}
              className={clsx(
                styles.subMenuLink,
                styles.nestedSubMenuLink,
                'u-menu-link'
              )}
            />
          </li>
        ))}
      </ul>
    </>
  );
}
