import gql from 'graphql-tag';

import { categoryFields } from './menu-item-fragment';

const ctaFragment = gql`
  fragment ctaFields on CTAButton {
    typename: __typename
    id: _id
    icon
    title
    internalLink: internal_link {
      typename: __typename
      ... on Model {
        slug: _slug
      }
      
      ... on SubCategory {
        ${categoryFields}

        category {
          ${categoryFields}
        }
      }

      ... on SubSubCategory {
        ${categoryFields}

        category {
          ${categoryFields}

          category {
            ${categoryFields}
          }
        }
      }

    }
    externalLink: external_link
    file {
      url
    }
  }
`;

export default ctaFragment;
