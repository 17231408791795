import Icon from '@atoms/Icon/Icon';
import TextInput from '@atoms/Inputs/TextInput';
import RichText from '@atoms/RichText/RichText';
import useNewsletterSignup from '@hooks/useNewsletterSignup';
import useStore from '@hooks/useStore';
import clsx from 'clsx';

import styles from './NewsletterSignup.module.scss';

interface Props {
  title?: string;
  text?: string;
  className?: string;
  formId: string;
}

const translations = {
  inputLabel: {
    nl: 'Je e-mailadres',
    en: 'Your e-mail address',
  },
  submitLabel: {
    nl: 'Versturen',
    en: 'Send',
  },
  errorMessage: {
    nl: 'Er is iets misgegaan. Probeer het later nog eens.',
    en: 'Something went wrong. Please try again later.',
  },
};

export default function NewsletterSignup({
  title,
  text,
  className,
  formId,
}: Props) {
  const {
    store: { locale },
  } = useStore();
  const { isSubmitted, error, isLoading, inputs, handleChange, handleSubmit } =
    useNewsletterSignup({ formId });

  return (
    <div className={clsx(isSubmitted && styles.submitted, className)}>
      {!!title && <p className={styles.title}>{title}</p>}

      {text && <RichText text={text} className={styles.text} />}

      <form className={styles.form} onSubmit={handleSubmit}>
        <fieldset
          className={styles.inputContainer}
          disabled={isLoading || isSubmitted}
        >
          <TextInput
            hideLabel
            id="email"
            name="email"
            type="email"
            label={translations.inputLabel[locale]}
            placeholder={translations.inputLabel[locale]}
            className={styles.input}
            onChange={handleChange}
            value={inputs.email}
            required
            icon="SEND_MAIL"
          />
          <button
            type="submit"
            aria-label={translations.submitLabel[locale]}
            className={styles.submit}
          >
            <Icon
              icon="SEND_DIAGONAL"
              className={clsx(styles.icon, styles.iconDiagonal)}
            />
            <Icon
              icon="CHECK"
              className={clsx(styles.icon, styles.iconCheck)}
            />
          </button>
        </fieldset>

        {!isSubmitted && (
          <div
            className={clsx(
              !inputs.email && 'u-visually-hidden',
              'g-recaptcha'
            )}
            data-sitekey="6LcwIw8TAAAAACP1ysM08EhCgzd6q5JAOUR1a0Go"
          />
        )}

        {error && <p className={styles.errorMessage}>{error}</p>}
      </form>
    </div>
  );
}
