import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

export interface Store {
  bookmarks: string[];
}

type Props = { bookmarks: string[] } & PropsWithChildren;

// TODO: REMOVE ANY TYPE. Allow setBookmarks.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StoreContext = createContext<
  Props & { setBookmarks: Dispatch<SetStateAction<Store>> }
>({
  bookmarks: [],
  setBookmarks: () => {},
});

// Export the provider as we need to wrap the entire app with it
export function BookmarksProvider({ bookmarks = [], children }: Props) {
  const [store, setBookmarks] = useState<Store>({
    bookmarks,
  });

  // Make the provider update only when it should.
  // We only want to force re-renders if the store state changes.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly!
  // we want to keep things very performant.
  const memoedValue = useMemo(
    () => ({ bookmarks: store.bookmarks, setBookmarks }),
    [store, setBookmarks]
  );

  return (
    <StoreContext.Provider value={memoedValue}>
      {children}
    </StoreContext.Provider>
  );
}

// Let's only export the `useStore` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useStoreBookmarks() {
  return useContext(StoreContext);
}
