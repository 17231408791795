import { PaletteVariants } from '@type-declarations/hero';
import { Page } from '@type-declarations/page';

interface Props {
  page?: Page;
}

export const getColorPalette = ({ page }: Props): PaletteVariants => {
  if (
    !page ||
    ['NewsPage', 'EventPage', 'LocationPage', 'RoutePage'].includes(
      page.typename
    )
  ) {
    return 'brand';
  }

  /* If valid option is selected, return value */
  if ('colorPalette' in page && page.colorPalette) {
    return page.colorPalette;
  }

  if ('category' in page) {
    if (page.category?.[0]?.colorPalette) {
      return page.category[0].colorPalette;
    }

    if (page.category?.[0]?.category?.[0]?.colorPalette) {
      return page.category[0]?.category?.[0]?.colorPalette;
    }

    if (page.category?.[0]?.category?.[0]?.category?.[0]?.colorPalette) {
      return page.category[0]?.category?.[0]?.category?.[0]?.colorPalette;
    }
  }

  /* Use Brand color as fallback if no color is selected */
  return 'brand';
};
