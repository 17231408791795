import Icon from '@atoms/Icon/Icon';
import { Locale } from '@type-declarations/locale';
import clsx from 'clsx';
import { useRouter } from 'next/router';

import styles from './CloseButton.module.scss';

interface Props {
  onClick: () => void;
  className?: string;
  controls?: {
    id: string;
    expanded: boolean;
  };
}

const translations = {
  nl: 'Sluiten',
  en: 'Close',
};

export default function CloseButton({ onClick, className, controls }: Props) {
  const locale = useRouter().locale as Locale;

  return (
    <button
      onClick={onClick}
      type="button"
      aria-label={translations[locale]}
      aria-expanded={controls?.expanded}
      aria-controls={controls?.id}
      className={clsx(styles.button, className)}
    >
      <Icon icon="XMARK" className={styles.icon} />
    </button>
  );
}
