/* Format distance in meters */

export function formatDistance(distance: number, numberOnly = false): string {
  if (distance > 1000) {
    return `${Math.round(distance / 10) / 100} ${!numberOnly ? 'km' : ''}`;
  }

  return `${distance} ${!numberOnly ? 'm' : ''}`;
}

export function formatDistanceRange(from: number, to: number): string {
  return `${formatDistance(
    from,
    from === 0 || from >= 1000
  )} - ${formatDistance(to)}`;
}
