import CloseButton from '@molecules/CloseButton/CloseButton';
import clsx from 'clsx';
import { PropsWithChildren, useEffect, useRef } from 'react';

import styles from './MainPanelItem.module.scss';

interface Props {
  id: string;
  title: string;
  closePanel: () => void;
}

export default function MainPanelItem({
  id,
  title,
  closePanel,
  children,
}: PropsWithChildren<Props>) {
  const ref = useRef<HTMLParagraphElement>(null!);

  useEffect(() => {
    ref.current.focus();
  }, []);

  return (
    <div id={id} className={clsx(styles.container, 'u-container-width')}>
      <p tabIndex={-1} className={styles.title} ref={ref}>
        {title}
        <CloseButton onClick={closePanel} className={styles.close} />
      </p>

      {children}
    </div>
  );
}
