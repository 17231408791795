import useStore from '@hooks/useStore';
import { getContentItems } from '@lib/prepr/content-item';
import getInternalRoute from '@utils/getInternalRoute';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';

import styles from './RichText.module.scss';

interface Props {
  text: string;
  className?: string;
  store?: boolean;
}

/* Regex for removing anchor tags */
const anchorRegex = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1[^>]*>(.*?)<\/a>/gi;

// Regex pattern to find UUIDs
const uuidRegex =
  /#[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/g;

function RichText({ text, store, className }: Props) {
  const preview = useMemo(
    () =>
      text
        .replaceAll('\r', '<br>')
        .replaceAll('<a', '<span')
        .replaceAll('</a>', '</span>'),
    [text]
  );
  const [HTML, setHTML] = useState<string>('');

  const {
    store: { locale },
  } = useStore();

  useEffect(() => {
    const uuids = text.match(uuidRegex);

    if (!uuids?.length) {
      setHTML(text.replaceAll('\r', '<br>'));
      return;
    }

    const getInternalLinks = async (ids: RegExpMatchArray) => {
      /* Get content items */
      const pages = await getContentItems({
        idAny: ids.map(id => id.substring(1)),
        allTypes: true,
        locale,
        limit: 100,
      });

      let newHTML = text;
      pages.items?.forEach(page => {
        newHTML = newHTML.replaceAll(
          `puuid#${page.id}`,
          `/${locale}/${getInternalRoute({
            internalLink: page,
            locale,
          })}`
        );
      });
      newHTML = newHTML.replaceAll('\r', '<br>');

      setHTML(newHTML);
    };

    getInternalLinks(uuids);
  }, [locale, text]);

  if (store) {
    return (
      <div
        className={clsx(styles.content, className)}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: HTML.replace(
            anchorRegex,
            (_0, _1, _2, p3) => p3 // return the label text
          ),
        }}
      />
    );
  }

  return (
    <div
      className={clsx(styles.content, className)}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: HTML || preview,
      }}
    />
  );
}

export default RichText;
