import { DETAIL_PAGES } from '@constants/constants';
import { fetchPrepr } from '@lib/prepr/prepr';
import CONTENT_ITEMS_QUERY from '@lib/queries/contentItems';
import { OverviewItem } from '@type-declarations/overviewItems';
import { QueryParameters } from '@type-declarations/prepr';

export interface ContentItemsResponse {
  data: {
    ContentItems: {
      items: OverviewItem[];
      total: number;
    };
  };
}

/* Content items */
export async function getContentItems({
  exploreTags,
  page,
  locale,
  preview,
  limit,
  idAny,
  allTypes = false,
}: QueryParameters): Promise<{
  items: OverviewItem[];
  total: number;
}> {
  const res = await fetchPrepr<ContentItemsResponse>({
    query: CONTENT_ITEMS_QUERY({ filterExploreTags: !!exploreTags }),
    variables: {
      limit,
      page,
      exploreTags,
      locale,
      preview,
      types: allTypes ? null : DETAIL_PAGES,
      idAny,
    },
  });

  return {
    items: res?.data?.ContentItems.items || [],
    total: res?.data?.ContentItems.total || 0,
  };
}
