import '@atoms/NProgress/NProgress.scss';
import '@styles/_base.scss';

import Favicons from '@atoms/Favicons/Favicons';
import NProgress from '@atoms/NProgress/NProgress';
import { StoreProvider } from '@hooks/useStore';
import { BookmarksProvider } from '@hooks/useStoreBookmarks';
import PreviewMessage from '@molecules/PreviewMessage/PreviewMessage';
import BaseTemplate from '@templates/BaseTemplate/BaseTemplate';
import { HeroVariant } from '@type-declarations/hero';
import { Page } from '@type-declarations/page';
import clsx from 'clsx';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import localFont from 'next/font/local';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import type { ReactElement, ReactNode } from 'react';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

export type NextPageWithLayout<Props = object, InitialProps = Props> = NextPage<
  Props,
  InitialProps
> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

interface PageProps {
  preview?: boolean;
  page: Page;
  [key: string]: unknown;
}

type AppPropsWithLayout = AppProps<PageProps> & {
  Component: NextPageWithLayout;
};

const CalibreFont = localFont({
  variable: '--font-family-base',
  src: [
    {
      path: '../public/fonts/Calibre-Light.woff2',
      weight: '300',
      style: 'normal',
    },
    {
      path: '../public/fonts/Calibre-Regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../public/fonts/Calibre-Medium.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../public/fonts/Calibre-Semibold.woff2',
      weight: '600',
      style: 'normal',
    },
  ],
});

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const { preview, page } = pageProps;
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = async () => {
      // We need a timeout here to give the route change some time to update the prepr-id meta
      // eslint-disable-next-line no-promise-executor-return
      await new Promise(resolve => setTimeout(resolve, 1000));
      prepr('event', 'View');
    };
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Use the layout defined at the page level, if available
  const getLayout =
    Component.getLayout ||
    (pageContent => (
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      <BaseTemplate
        heroVariant={
          (page &&
            'heroVariant' in page &&
            (page.heroVariant as HeroVariant)) ||
          'small'
        }
        seo={page && 'seo' in page ? page?.seo : undefined}
        title={page && 'title' in page ? page?.title : undefined}
        image={page && 'image' in page ? page?.image : undefined}
        introText={page && 'introText' in page ? page?.introText : undefined}
        page={page}
      >
        {pageContent}
      </BaseTemplate>
    ));

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        {page && 'id' in page && page?.id && (
          <meta property="prepr:id" content={page.id} />
        )}
        <Favicons />
      </Head>

      <Script
        id="prepr-connection"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
          ! function (e, t, p, r, n, a, s) {
          e[r] || ((n = e[r] = function () {
          n.process ? n.process.apply(n, arguments) : n.queue.push(arguments)
          }).queue = [], n.t = +new Date, (a = t.createElement(p)).async = 1, a.src = "https://cdn.tracking.prepr.io/js/prepr_v2.min.js?t=" + 864e5 * Math.ceil(new Date / 864e5), (s = t.getElementsByTagName(p)[0]).parentNode.insertBefore(a, s))
          }(window, document, "script", "prepr"), prepr("init", "${process.env.PREPR_TRACKING_TOKEN}"), prepr("event", "pageload");
          `,
        }}
      />

      <NProgress />
      <ToastContainer
        position="bottom-left"
        autoClose={1500}
        hideProgressBar={false}
        pauseOnHover
        theme="light"
        limit={3}
      />
      <PreviewMessage preview={preview} />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      {/* @ts-expect-error TODO: Sync pageprops with store props */}
      <StoreProvider store={pageProps}>
        <BookmarksProvider bookmarks={[]}>
          <div className={clsx('nextFonts', CalibreFont.variable)}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {getLayout(<Component {...pageProps} />)}
          </div>
        </BookmarksProvider>
      </StoreProvider>
    </>
  );
}
