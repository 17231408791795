// TODO: Convert to Typescript

import { useEffect, useState } from 'react';

export default function useForm(initial = {}) {
  // create a state object for our inputs
  const [inputs, setInputs] = useState(initial);
  const initialValues = Object.values(initial).join('');

  useEffect(() => {
    // This function runs when the things we are watching change
    setInputs(initial);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  function handleChange(e) {
    let { value } = e.target;
    const { name, type, checked } = e.target;

    if (type === 'number') {
      value = parseInt(value, 10);
    }

    if (type === 'date') {
      // native date input has format 'yyyy-MM-dd'  ex. '2023-10-06'
    }

    if (type === 'checkbox') {
      if (typeof inputs[name] === 'boolean') {
        value = checked;
      } else {
        value = checked
          ? [...(inputs[name] || []), value]
          : inputs[name].filter(v => v !== value);
      }
    }
    if (type === 'file') {
      // This will get the first file in the array
      [value] = e.target.files;
    }

    if (type === 'radio') {
      if (value === inputs[name]) {
        value = '';
      }
    }

    setInputs(state => ({
      // copy the existing state
      ...state,
      [name]: value,
    }));
  }

  function setMultipleValues(values) {
    setInputs({ ...inputs, ...values });
  }

  function resetForm(obj) {
    setInputs(obj || initial);
  }

  function clearForm() {
    const blankState = Object.fromEntries(
      Object.entries(inputs).map(([key]) => [key, ''])
    );
    setInputs(blankState);
  }

  // return the things we want to surface from this custom hook
  return {
    inputs,
    handleChange,
    setMultipleValues,
    resetForm,
    clearForm,
  };
}
