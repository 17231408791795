import Button from '@atoms/Button/Button';

import styles from './PreviewMessage.module.scss';

interface Props {
  preview?: boolean;
}

export default function PreviewMessage({ preview = false }: Props) {
  if (!preview) return null;

  return (
    <Button
      icon="XMARK"
      label="Preview modus sluiten"
      href="/api/exit-preview"
      className={styles.button}
      noAnimation
    />
  );
}
