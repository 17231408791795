import { categoryFields } from '@lib/fragments/menu-item-fragment';
import {
  contentItem,
  eventItem,
  locationItem,
  newsItem,
  routeItem,
} from '@lib/fragments/overview-fragment';
import gql from 'graphql-tag';

interface Props {
  filterCategories?: boolean;
  filterTags?: boolean;
  filterExploreTags?: boolean;
  filterStoreExploreTags?: boolean;
}

const CONTENT_ITEMS_QUERY = ({
  filterCategories,
  filterTags,
  filterExploreTags,
  filterStoreExploreTags,
}: Props) => gql`
  query CONTENT_ITEMS(
    $locale: String
    $limit: Int
    $types: [String!]
    ${filterCategories ? '$categories: [String!]' : ''}
    ${filterTags ? '$tags: [String!]' : ''}
    ${
      filterExploreTags || filterStoreExploreTags
        ? '$exploreTags: [String!]'
        : ''
    }
    $idAny: [String]
    $idNany: [String]
    $skip: Int
  ) {
    ContentItems(
      skip: $skip
      locale: $locale
      limit: $limit
      where: {
        _id_any: $idAny
        _id_nany: $idNany
        _typename_any: $types
        ${filterCategories ? 'category: { _slug_any: $categories }' : ''}
        ${filterTags ? 'content_tags: { _slug_any: $tags }' : ''}
        ${filterExploreTags ? 'explore_tags: { _slug_any: $exploreTags }' : ''}
        ${
          filterStoreExploreTags
            ? 'store_explore_tags: { _slug_any: $exploreTags }'
            : ''
        }
      }
    ) {
      total
      items {
        ... on Model {
          id: _id
          typename: __typename
          slug: _slug
        }
        ...locationItem
        ...eventItem
        ...contentItem
        ...routeItem
        ...newsItem

        ... on SubCategory {
        ${categoryFields}

        category {
          ${categoryFields}
        }
      }

      ... on SubSubCategory {
        ${categoryFields}

        category {
          ${categoryFields}

          category {
            ${categoryFields}
          }
        }
      }

      }
    }
  }
  ${locationItem}
  ${eventItem}
  ${contentItem}
  ${routeItem}
  ${newsItem}
`;

export default CONTENT_ITEMS_QUERY;
