import { FormEvent, useEffect, useState } from 'react';

import useForm from './useForm';
import useTranslations from './useTranslations';

export default function useNewsletterSignup({ formId }: { formId: string }) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [recaptchaRendered, setRecaptchaRendered] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { inputs, handleChange }: any = useForm({
    email: '',
  });
  const t = useTranslations();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData(e.currentTarget);
    // Hidden field key/values.
    formData.append('u', formId); // ActiveCampaign list ID.
    formData.append('f', formId); // ActiveCampaign form ID.
    formData.append('s', 's'); // ActiveCampaign form submission.
    formData.append('c', '0'); // ActiveCampaign form captcha.
    formData.append('m', '0'); // ActiveCampaign form mobile.
    formData.append('act', 'sub'); // ActiveCampaign form action.
    formData.append('v', '2'); // ActiveCampaign form version.
    formData.append('or', '1de4d3203d1383007bf6048ccb6c413e'); // ActiveCampaign form hash.

    // Pass FormData values into a POST request to ActiveCampaign.
    // Mark form submission successful, otherwise return error state.
    fetch('https://bredamarke12017.activehosted.com/proc.php?jsonp=true', {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'application/json',
      },
    })
      .then(async res => {
        const json = await res.json();

        if (json.action === 'render_captcha' && json.data.id === '1') {
          setIsLoading(false);
          setError(t.captchaRequired);
          return;
        }

        setIsLoading(false);
        setIsSubmitted(true);
        setError('');
      })
      .catch(() => {
        setIsLoading(false);
        setError(t.somethingWentWrong);
      });
  };

  const renderRecaptcha = () => {
    if (window.grecaptcha && !recaptchaRendered) {
      const recaptchas = document.querySelectorAll('.g-recaptcha');
      recaptchas.forEach((recaptcha, i) => {
        const iframe = recaptcha.querySelector('iframe');
        // If the recaptcha is already rendered, return.
        if (iframe) return;
        // Set the recaptcha element ID, so the recaptcha can be applied to each element.
        const recaptchaId = `recaptcha_${i}`;
        // eslint-disable-next-line no-param-reassign
        recaptcha.id = recaptchaId;
        const el = document.getElementById(recaptchaId);
        if (el != null) {
          const sitekey = el.getAttribute('data-sitekey');
          const stoken = el.getAttribute('data-stoken');
          window.grecaptcha.render(recaptchaId, {
            sitekey,
            stoken,
          });
          setRecaptchaRendered(true);
        }
      });
    }
  };

  useEffect(() => {
    if (window.grecaptcha) {
      renderRecaptcha();
    } else {
      const script = document.createElement('script');
      script.src =
        'https://www.google.com/recaptcha/api.js?onload=recaptcha_callback&render=explicit';
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        window.recaptcha_callback = renderRecaptcha;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isSubmitted,
    error,
    isLoading,
    inputs,
    handleChange,
    handleSubmit,
  };
}
