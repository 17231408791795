import { LOCALES, ROUTES } from '@constants/constants';
import { Category, InternalLink, Locale } from '@type-declarations/prepr';

export default function getInternalRoute({
  internalLink: { slug, typename, category },
  locale,
  store = false,
}: {
  internalLink: InternalLink;
  locale: Locale;
  store?: boolean;
}): string {
  let route = '/'; // returns homepage on default (if no matches)
  switch (typename) {
    case 'SharedListPage':
    case 'NewsOverviewPage':
    case 'EventOverviewPage':
    case 'LocationOverviewPage':
    case 'RouteOverviewPage':
    case 'MapPage':
    case 'ExplorePage':
      route = ROUTES[typename][locale];
      break;
    case 'NewsPage':
      if (store) {
        route = `/store/blogs/${slug}`;
      } else {
        route = `${ROUTES.NewsOverviewPage[locale]}/${slug}`;
      }
      break;
    case 'EventPage':
      if (store) {
        route = `/store/events/${slug}`;
      } else {
        route = `${ROUTES.EventOverviewPage[locale]}/${slug}`;
      }
      break;
    case 'LocationPage':
      if (store) {
        route = `/store/locations/${slug}`;
      } else {
        route = `${ROUTES.LocationOverviewPage[locale]}/${slug}`;
      }
      break;
    case 'RoutePage':
      if (store) {
        route = `/store/routes/${slug}`;
      } else {
        route = `${ROUTES.RouteOverviewPage[locale]}/${slug}`;
      }
      break;
    case 'HomePage':
      route = `/`;
      break;
    case 'StoreMapPage':
      route = '/store/map';
      break;
    case 'ContentPage':
    case 'LandingPage':
      if (store) {
        route = `/store/content/${slug}`;
      } else {
        route = `${slug}`;
      }
      break;
    case 'MainCategory':
    case 'SubCategory':
    case 'SubSubCategory': {
      /* Return the translated slug of a category */
      const getCategorySlug = (
        cat?: Category
      ): { slug?: string } | undefined => {
        if (!cat) return undefined;

        return {
          slug: cat.localizations.find(loc => loc.locale === LOCALES[locale])
            ?.slug,
        };
      };

      /* Array of available category slugs */
      const categories = [
        getCategorySlug(category?.at(0)),
        getCategorySlug(category?.at(0)?.category?.at(0)),
        getCategorySlug(category?.at(0)?.category?.at(0)?.category?.at(0)),
      ]; // [level 1, level 2, level 3]

      if (categories.findIndex(cat => cat && cat.slug === undefined) > -1) {
        console.warn('Not all categories are translated', {
          category,
          locale,
        });
        break;
      }

      route = categories.reduce((acc, cur) => {
        if (!cur?.slug) return acc;

        return `${cur.slug}/${acc}`;
      }, slug!);

      break;
    }
    case 'ZigzagCampaignPage': {
      route = `${ROUTES.ZigzagCampaignPage[locale]}`;
      break;
    }
    default:
      console.warn('Page type not recognized', { typename, slug, locale });
  }

  return route;
}
