import { routeChangeStart } from '@atoms/NProgress/NProgress';
import getInternalUrl from '@utils/getInternalUrl';
import setExternalUrl from '@utils/setExternalUrl';
import Link from 'next/link';
import { forwardRef, PropsWithChildren } from 'react';

/**
 * Wrap In Link
 */
interface Props extends PropsWithChildren {
  href: string;
  title?: string;
  className?: string;
  target?: string;
  attributes?: { [key: string]: unknown };
}

const WrapInLink = forwardRef<HTMLAnchorElement, Props>(
  ({ href, title, className, attributes, children, target }: Props, ref) => {
    const internalUrl = getInternalUrl(href);
    if (internalUrl) {
      return (
        <Link
          ref={ref}
          href={internalUrl}
          className={className}
          title={title}
          itemProp="url"
          target={target}
          onClick={routeChangeStart}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...attributes}
        >
          {children}
        </Link>
      );
    }

    return (
      <a
        ref={ref}
        href={setExternalUrl(href)}
        target="_blank"
        rel="noopener"
        itemProp="url"
        className={className}
        title={title}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...attributes}
      >
        {children}
      </a>
    );
  }
);

WrapInLink.displayName = 'WrapInLink';

export default WrapInLink;
