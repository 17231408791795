import Button from '@atoms/Button/Button';
import useTranslations from '@hooks/useTranslations';
import { StoreModifier } from '@type-declarations/modifier';
import clsx from 'clsx';

import styles from './EmptyMessage.module.scss';

interface Props {
  title: string;
  text: string;
  url: string;
  modifier?: StoreModifier;
}

export default function EmptyMessage({ title, text, url, modifier }: Props) {
  const t = useTranslations();

  return (
    <div className={clsx(styles.container, modifier && styles[modifier])}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.text}>{text}</p>
      {url && (
        <Button
          className={styles.cta}
          iconLeft="WALKING"
          modifier={modifier}
          label={t.emptyMessageButton}
          href={url}
        />
      )}
    </div>
  );
}
