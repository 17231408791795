import { FOCUSABLE_ELS_SELECTOR } from '@constants/constants';
import { RefObject, useState } from 'react';

export default function useFocusTrap(
  ref: RefObject<HTMLElement>,
  toggleRef: RefObject<HTMLElement>
) {
  const [selector, setSelector] = useState<string>('');

  const shouldTrapFocus = (e: KeyboardEvent): boolean => {
    const isTabPressed = e.key === 'Tab' || e.keyCode === 9;
    if (!isTabPressed) return false;
    return true;
  };

  const trapFocus = (e: KeyboardEvent): void => {
    if (!shouldTrapFocus(e) || !ref.current) return;

    const focusableEls = ref.current.querySelectorAll<
      | HTMLInputElement
      | HTMLButtonElement
      | HTMLTextAreaElement
      | HTMLAnchorElement
    >(selector || FOCUSABLE_ELS_SELECTOR);

    const lastElementIsFocused =
      document.activeElement === focusableEls[focusableEls.length - 1];

    // Trap focus in the current list of elements
    if (e.shiftKey) {
      if (document.activeElement === toggleRef.current) {
        focusableEls[focusableEls.length - 1].focus();
        e.preventDefault();
      }
    } else if (lastElementIsFocused) {
      focusableEls[0].focus();
      e.preventDefault();
    }
  };

  const disableFocusTrap = () => {
    ref.current?.removeEventListener('keydown', trapFocus);
  };

  const enableFocusTrap = (customSelector?: string) => {
    if (customSelector) {
      setSelector(customSelector);
    }

    // Make sure all old eventListeners are killed
    disableFocusTrap();

    ref.current?.addEventListener('keydown', trapFocus);
  };

  return { trapFocus, enableFocusTrap, disableFocusTrap };
}
