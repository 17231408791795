export const tagFragment = `
  typename: __typename
  id: _id
  slug: _slug
  title
  icon
  theme
  highlightedDate: highlighted_date {
    from
    until
  }
  bannerTitle: banner_title
  bannerText: banner_text
  bannerCta: banner_cta {
    ...ctaFields
  }
`;

export default tagFragment;
