import { toast } from 'react-toastify';

export default async function handleShare({
  urlCopied,
  title,
  text,
  appendedUrl,
}: {
  urlCopied: string;
  title?: string;
  text?: string;
  appendedUrl?: string;
}) {
  const isMobile = navigator.userAgent.match(
    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  );

  const url = appendedUrl
    ? `${process.env.ENVIRONMENT_URL}${appendedUrl}`
    : document.URL;

  if (navigator.share && isMobile) {
    try {
      await navigator.share({
        title,
        text,
        url,
      });
    } catch (error) {
      console.error(error);
    }
  } else {
    navigator.clipboard.writeText(url);
    toast(urlCopied);
    // Fallback behavior when navigator.share is not available
    console.error('Sharing is not supported in this browser.');
  }

  window.dataLayer?.push({
    event: 'share',
    shareUrl: url,
  });
}
