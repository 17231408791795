import { Image } from '@type-declarations/media';
import { SeoProps } from '@type-declarations/seo';
import Head from 'next/head';

interface Props {
  title?: string;
  description?: string;
  image?: Image;
  suffix?: string;
  seo?: SeoProps;
}

function Seo({ title, image, description, suffix, seo }: Props) {
  const pageTitle =
    seo?.metaTitle || [title, suffix].filter(part => !!part).join(' - ');

  return (
    <Head>
      {/* HTML Meta Tags */}
      <title>{pageTitle}</title>

      <meta name="twitter:card" content="summary_large_image" />

      {(seo?.metaDescription || description) && (
        <>
          <meta
            name="description"
            content={
              seo?.metaDescription || description!.replace(/(<([^>]+)>)/gi, '')
            }
          />
          <meta
            property="og:description"
            content={
              seo?.metaDescription || description!.replace(/(<([^>]+)>)/gi, '')
            }
          />
          <meta
            name="twitter:description"
            content={
              seo?.metaDescription || description!.replace(/(<([^>]+)>)/gi, '')
            }
          />
        </>
      )}

      <meta property="og:title" content={seo?.metaTitle || pageTitle} />
      <meta name="twitter:title" content={seo?.metaTitle || pageTitle} />

      {(seo?.ogImage?.url || image) && (
        <>
          <meta property="og:image" content={seo?.ogImage?.url || image?.url} />
          <meta
            name="twitter:image"
            content={seo?.ogImage?.url || image?.url}
          />
        </>
      )}
    </Head>
  );
}

export default Seo;
