import NavigationItem from '@atoms/NavigationItem/NavigationItem';
import useStore from '@hooks/useStore';
import useWindowSize from '@hooks/useWindowSize';
import { MenuItem } from '@type-declarations/menu';
import clsx from 'clsx';
import { NavArrowDown } from 'iconoir-react';
import { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import styles from './FooterNavigation.module.scss';

const navigationButtonLabel = {
  nl: 'Open navigatie menu op mobiel voor',
  en: 'Open navigation menu on mobile for',
};

interface Props {
  id: string;
  title: string;
  titleClass: string;
  expanded: boolean;
  items: MenuItem[];
  onClick: (id: string) => void;
}

export default function FooterNavigation({
  id,
  title,
  titleClass,
  expanded,
  items,
  onClick,
}: Props) {
  const {
    store: { locale },
  } = useStore();

  const { width: windowWidth } = useWindowSize();
  const shouldUseExpandFunctionality = windowWidth < 768;
  const [itemHeight, setItemHeight] = useState<string>('0');
  const itemRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    setItemHeight(`${itemRef?.current?.offsetHeight || 0}px`);
  }, [expanded]);

  const style = { '--navigation-height': itemHeight } as React.CSSProperties;

  return (
    <>
      <button
        type="button"
        className={styles.trigger}
        onClick={() => onClick(id)}
        aria-label={`${navigationButtonLabel[locale]} ${title}`}
        aria-expanded={!shouldUseExpandFunctionality ? true : expanded}
        aria-controls={`footer-navigation-${id}`}
        disabled={!shouldUseExpandFunctionality}
      >
        <p className={titleClass}>{title}</p>
        <NavArrowDown
          width="18"
          height="18"
          className={clsx(styles.icon, expanded && styles.iconAnimate)}
        />
      </button>
      <div id={`footer-navigation-${id}`} style={style}>
        <CSSTransition
          in={!shouldUseExpandFunctionality || expanded}
          timeout={300}
          unmountOnExit
          classNames={{
            enter: styles.fadeEnter,
            enterActive: styles.fadeEnterActive,
            exit: styles.fadeExit,
            exitActive: styles.fadeExitActive,
          }}
        >
          <div className={styles.navigationWrapper}>
            <ul
              className={clsx(
                styles.navigation,
                expanded && styles.navigationOpen,
                'u-list-clean'
              )}
              ref={itemRef}
              data-gtm-id="footer-navigation"
            >
              {items.map((item, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={`${item.id}-${i}`}>
                  <NavigationItem item={item} />
                </li>
              ))}
            </ul>
          </div>
        </CSSTransition>
      </div>
    </>
  );
}
