import { forwardRef, PropsWithChildren } from 'react';

/**
 * Wrap in Button
 */
interface Props extends PropsWithChildren {
  child: JSX.Element;
  className: string;
  title?: string;
  submit: boolean;
  attributes: { [key: string]: unknown };
}

const WrapInButton = forwardRef<HTMLButtonElement, Props>(
  ({ className, title, submit, attributes, children }: Props, ref) => (
    <button
      ref={ref}
      className={className}
      type={submit ? 'submit' : 'button'}
      title={title}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...attributes}
    >
      {children}
    </button>
  )
);

WrapInButton.displayName = 'WrapInButton';

export default WrapInButton;
