import Button from '@atoms/Button/Button';
import Icon from '@atoms/Icon/Icon';
import { FONT_SIZE_COOKIE, ROUTES } from '@constants/constants';
import useStore from '@hooks/useStore';
import useTranslations from '@hooks/useTranslations';
import AddIcon from '@svg/add.svg';
import RemoveIcon from '@svg/remove.svg';
import { StoreModifier } from '@type-declarations/modifier';
import { getCookie, setCookie } from '@utils/clientCookies';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import styles from './AccessibilityPanel.module.scss';

type FontSizeOption = 0 | 1 | 2;
const options = [1, 1.25, 1.5];
const inStoreOptions = [1, 1.1, 1.25];

interface Props {
  modifier?: StoreModifier;
  title?: string;
  text?: string;
}

export default function AccessibilityPanel({ modifier, title, text }: Props) {
  const {
    store: { showAccessibilityPanel, locale },
    setStore,
  } = useStore();

  const t = useTranslations();
  const { asPath } = useRouter();

  const [fontSize, setFontSize] = useState<FontSizeOption>(0);
  const [sizeOptions] = useState(
    modifier === 'inStore' ? inStoreOptions : options
  );

  useEffect(() => {
    const fs = getCookie(FONT_SIZE_COOKIE);

    if (fs) {
      setFontSize(Number(fs) as FontSizeOption);
    }
  }, [setStore, showAccessibilityPanel]);

  /* Update font-size property on the :root element */
  useEffect(() => {
    const root: HTMLElement | null = document.querySelector(':root');
    if (!root) return;

    root.style.fontSize = `${sizeOptions[fontSize] * 16}px`;
  }, [fontSize, sizeOptions]);

  /* In- or decrease font size on :root element */
  const updateFontSize = (direction = 'increase') => {
    setFontSize(prevState => {
      let newValue = prevState;

      if (direction === 'increase') {
        newValue += 1;
      }

      if (direction === 'decrease') {
        newValue -= 1;
      }

      setCookie(FONT_SIZE_COOKIE, String(newValue));

      return newValue as FontSizeOption;
    });
  };

  const handleButton = () => {
    setStore(s => ({
      ...s,
      showAccessibilityPanel: false,
    }));
  };

  if (!showAccessibilityPanel) {
    return null;
  }

  return (
    <div className={clsx(styles.panel, modifier && styles[modifier])}>
      <div className={styles.content}>
        <div className={styles.header}>
          <h2 className={styles.title}>{t.accessibility}</h2>
          <button
            onClick={handleButton}
            type="button"
            aria-label={t.closeA11y}
            className={styles.close}
          >
            <Icon icon="XMARK" className={styles.closeIcon} />
          </button>
        </div>

        {/* Update fontsize */}
        <p className={styles.fontSize}>{t.fontSize}</p>
        <div className={styles.fontSizeContainer}>
          <p className="u-mb-0">{Math.round(100 * sizeOptions[fontSize])}%</p>
          <div className={styles.buttonsContainer}>
            <button
              type="button"
              onClick={() => updateFontSize('decrease')}
              aria-label={t.decreaseFontSize}
              title={t.decreaseFontSize}
              disabled={fontSize === 0}
              className={styles.sizeButton}
            >
              <RemoveIcon className={styles.sizeIcon} />
            </button>
            <button
              type="button"
              onClick={() => updateFontSize('increase')}
              aria-label={t.increaseFontSize}
              title={t.increaseFontSize}
              disabled={fontSize === options.length - 1}
              className={styles.sizeButton}
            >
              <AddIcon className={styles.sizeIcon} />
            </button>
          </div>
        </div>

        {/* Digital help */}
        <span className={styles.subtitle}>{title || t.a11yTitle}</span>
        <p className="u-mb-0">{text || t.a11yContent}</p>

        <Button
          href="tel:0800-1508"
          label="0800-1508"
          iconLeft="PHONE"
          variant="text"
          modifier="redIcon"
          className={styles.linkTel}
        />

        {asPath !== `/${ROUTES.a11y[locale]}` && (
          <Button
            variant="primary"
            label={t.a11yCta}
            href={`/${ROUTES.a11y[locale]}`}
            onClick={handleButton}
            noAnimation
            className={styles.submit}
            fullWidth
            icon="NAV_ARROW_RIGHT"
          />
        )}
      </div>
    </div>
  );
}
