import { BOOKMARK_COOKIE } from '@constants/constants';
import { getContentItems } from '@lib/prepr/content-item';
import { OverviewItem } from '@type-declarations/overviewItems';
import { setCookie } from '@utils/clientCookies';
import { useCallback, useEffect, useState } from 'react';

import useStoreBookmarks from './useStoreBookmarks';

export default function useBookmarks() {
  const { bookmarks, setBookmarks } = useStoreBookmarks();
  const [loading, setLoading] = useState<boolean>(true);

  const [{ items, total }, setBookmarkItems] = useState<{
    items: OverviewItem[];
    total: number;
  }>({ items: [], total: 0 });

  const fetchBookmarks = useCallback(async () => {
    if (!bookmarks?.length) {
      setLoading(false);
      setBookmarkItems({
        items: [],
        total: 0,
      });
      return;
    }

    setLoading(true);

    try {
      const result = await getContentItems({ idAny: bookmarks, limit: 500 });
      setBookmarkItems(result);

      // Bookmarks count is not the same as the total amount of results
      // this can be the case if content items are unpublished or deleted
      if (bookmarks.length !== result.total) {
        const onlineItems = result.items.map(item => item.id);
        // Update the store with the online items
        setCookie(BOOKMARK_COOKIE, JSON.stringify(onlineItems));
        setBookmarks({ bookmarks: onlineItems });
      }
    } catch (error) {
      console.error('Error fetching items:', error);
      setBookmarkItems({ items: [], total: 0 });
    } finally {
      setLoading(false);
    }
  }, [bookmarks, setBookmarks, setBookmarkItems]);

  useEffect(() => {
    fetchBookmarks();
  }, [fetchBookmarks]);

  return {
    loading,
    items,
    total,
    fetchBookmarks,
  };
}
