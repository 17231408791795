import clsx from 'clsx';

import styles from './Tag.module.scss';

interface Props {
  title: string;
  className?: string;
  variant?: 'sm' | 'md';
}

export default function Tag({ title, className, variant = 'md' }: Props) {
  return (
    <div className={clsx(styles.tag, className, styles[variant])}>{title}</div>
  );
}
