import type { PaletteVariants } from '@type-declarations/hero';

interface Props {
  colorPalette: PaletteVariants;
}

export default function ColorPalette({ colorPalette }: Props) {
  if (colorPalette === 'brand') return null;

  let palette: string = '';

  if (colorPalette === '1') {
    palette = `
    --color-bg-primary: var(--color-steel-500);
    --color-bg-primary-hover: var(--color-steel-400);
    --color-bg-primary-disabled: var(--color-steel-200);
    --color-bg-secondary: var(--color-green-500);
    --color-bg-secondary-hover: var(--color-green-400);
    --color-bg-secondary-disabled: var(--color-green-200);
    --color-fg-primary: var(--color-white);
    --color-fg-secondary: var(--color-black-500);
  `;
  }
  if (colorPalette === '2') {
    palette = `
      --color-bg-primary: var(--color-sand-500);
      --color-bg-primary-hover: var(--color-sand-400);
      --color-bg-primary-disabled: var(--color-sand-200);
      --color-bg-secondary: var(--color-yellow-500);
      --color-bg-secondary-hover: var(--color-yellow-400);
      --color-bg-secondary-disabled: var(--color-yellow-200);
      --color-fg-primary: var(--color-black-500);
      --color-fg-secondary: var(--color-black-500);
    `;
  }
  if (colorPalette === '3') {
    palette = `
      --color-bg-primary: var(--color-indigo-500);
      --color-bg-primary-hover: var(--color-indigo-400);
      --color-bg-primary-disabled: var(--color-indigo-200);
      --color-bg-secondary: var(--color-turquoise-500);
      --color-bg-secondary-hover: var(--color-turquoise-400);
      --color-bg-secondary-disabled: var(--color-turquoise-200);
      --color-fg-primary: var(--color-white);
      --color-fg-secondary: var(--color-black-500);
    `;
  }
  if (colorPalette === '4') {
    palette = `
      --color-bg-primary: var(--color-royal-500);
      --color-bg-primary-hover: var(--color-royal-400);
      --color-bg-primary-disabled: var(--color-royal-200);
      --color-bg-secondary: var(--color-gold-500);
      --color-bg-secondary-hover: var(--color-gold-400);
      --color-bg-secondary-disabled: var(--color-gold-200);
      --color-fg-primary: var(--color-white);
      --color-fg-secondary: var(--color-black-500);
    `;
  }

  if (!palette) return null;

  return <style id="palette-variables">{`:root {${palette}}`}</style>;
}
