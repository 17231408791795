import { Tag } from '@type-declarations/prepr';

export function findHighlightedTag(tags: Tag[]): Tag | null {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Normalize today to midnight

  return (
    tags?.find(tag => {
      const fromDate = tag?.highlightedDate?.from
        ? new Date(tag.highlightedDate.from)
        : null;
      const untilDate = tag?.highlightedDate?.until
        ? new Date(tag.highlightedDate.until)
        : null;

      if (fromDate) fromDate.setHours(0, 0, 0, 0); // Normalize fromDate to midnight
      if (untilDate) untilDate.setHours(0, 0, 0, 0); // Normalize untilDate to midnight

      return (
        tag.theme &&
        fromDate &&
        untilDate &&
        fromDate <= today &&
        untilDate >= today
      );
    }) || null
  );
}
