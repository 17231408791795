import styles from './ErrorMessage.module.scss';

interface Props {
  message?: string;
}

export default function ErrorMessage({ message }: Props) {
  if (!message) return null;

  return <p className={styles.message}>{message}</p>;
}
